import React from "react"
// import {Link} from 'gatsby';

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageContentBlock from "../components/imageContentBlock/imageContentBlock"
import FullWidth from "../components/fullWidth/fullWidth"
// import DynamicImage from '../components/ui/dynamicImage/dynamicImage';
import ModalCombiner from "../components/modalCombiner/modalCombiner"
import ContactForm from "../components/contact/contact"
import FullWidthContent from "../components/fullWidthContent/fullWidthContent"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Møbelpolstring på Frederiksberg og i København" />

      <FullWidthContent>
        <h1>Traditionel og moderne ombetrækning</h1>
        <p>
          Jeg ompolstrer og istandsætter traditionelle og antikke møbler såvel
          som moderne møbeltyper efter dine ønsker. Blandt mine specialer er
          fremstilling af puder og andet boligtilbehør i eget design.
        </p>
        <ModalCombiner />
      </FullWidthContent>

      {/* <div style={{ maxWidth: `500px` }}>
        <DynamicImage alt="Gatsby in Space" filename="brick-wall-with-graphic-street-art" />
      </div> */}

      <ImageContentBlock
        imagePosition="right"
        filename="Office-leather.jpg"
        alt="Ompolsteret kontorstol i cognac farvet læder"
      >
        <h2>Kvalitet og design</h2>
      </ImageContentBlock>

      <ImageContentBlock
        imagePosition="left"
        filename="chair.jpg"
        alt="Ompolsteret stol i læder"
      >
        <h2>Ompolstring med respekt</h2>
      </ImageContentBlock>

      <FullWidth
        imagePosition="top"
        filename="Yellow-Musti-Medium.jpg"
        alt="Pude i lysegult stof"
      >
        <h2>Specialdesignet tilbehør</h2>
        <p>
          I indretning af boligen kan specialdesignet tilbehør gøre en stor
          forskel i det samlede udtryk. Jeg laver puder, hynder og pyntepuder i
          egne designs og udført i tekstiler efter dit valg. Jeg hjælper med at
          udvælge de rette stoffer til præcis dine behov for indretning af
          hjemmet.
        </p>
      </FullWidth>

      <ImageContentBlock
        imagePosition="right"
        filename="HP5.jpg"
        alt="Pude i Grå Halland Kvadrat tekstil"
      >
        <h2>Puder i eget design</h2>
      </ImageContentBlock>

      <ImageContentBlock
        imagePosition="left"
        filename="after7+.jpg"
        alt="Ompolsteret gul lænestol"
      >
        <h2>Klassiske møbler</h2>
      </ImageContentBlock>

      <FullWidthContent>
        <div
          style={{
            backgroundColor: "#F7F7F7",
            borderRadius: "10px",
            padding: "30px 0 30px 0",
          }}
        >
          <h2 style={{ textAlign: "center" }}>Jeg hjælper med dit projekt:</h2>
          <ModalCombiner />
        </div>
      </FullWidthContent>

      <FullWidth
        imagePosition="top"
        filename="artander.jpg"
        alt="Artander ompolstreret lænestol"
      >
        <h2>Renovering med detaljer</h2>
        <p>
          Jeg elsker arbejdet med at give en ældre stol nyt liv. Jeg arbejder
          gerne i tæt dialog med kunden for at få præcis det rette udtryk ved
          renovering af stole, lænestole, sofaer eller andre møbler, der har
          været i familiens eje i mange år.
        </p>
      </FullWidth>

      <FullWidth
        imagePosition="top"
        filename="Chairs.jpg"
        alt="Ompolsterede lænestole og kontorstole af Studio Gafni"
      >
        <h2>Om Studio Gafni</h2>
        <p>
          Jeg hedder Atar Gafni og ejer Studio Gafni. Jeg har en baggrund i
          møbelpolstring samt industriel design og tilbyder derfor dig en
          værdifuld kombination og æstetisk sans for design kombineret med
          praktisk håndværk, når møblerne skal ompolstres.
          <br /> <br />
          Når vi starter en dialog op, omkring ompolstring af dit møbel eller en
          speciallavet pude, lægger jeg vægt på, vi tager en god snak om dine
          ønsker for opgaven og de materialer, der skal benyttes under arbejdet.
          <br /> <br />
          Jeg kan efter behov hjælpe med transporten af dine møbler til
          ombetrækning samt levering ved færdiggørelse af opgaven. Jeg har til
          huse i København og tilbyder møbelpolstring for private, virksomheder
          og offentlige institutioner i København, Frederiksberg,
          Hovedstadsområdet, Nordsjælland og Sjælland.
          <br /> <br />
          Udover den traditionelle møbelpolstring har jeg også arbejdet som
          konsulent på møbel prototyper for større møbelvirksomheder.
          <br /> <br />
          Du er meget velkommen til at kontakte mig for en uforpligtende snak
          gennem kontaktformularen her:
        </p>
      </FullWidth>

      {/* <FullWidthContent>
				<ModalCombiner />
			</FullWidthContent> */}

      {/* <FullWidthContent>
				<h2>Om Studio Gafni</h2>
				<p>
					Jeg hedder Atar Gafni og ejer Studio Gafni. Jeg har en baggrund i møbelpolstring samt industriel
					design og tilbyder derfor dig en værdifuld kombination og æstetisk sans for design kombineret med
					praktisk håndværk, når møblerne skal ompolstres.
					<br /> <br />
					Når vi starter en dialog op, omkring ompolstring af dit møbel eller en speciallavet pude, lægger jeg
					vægt på, vi tager en god snak om dine ønsker for opgaven og de materialer, der skal benyttes under
					arbejdet.
					<br /> <br />
					Jeg kan efter behov hjælpe med transporten af dine møbler til ombetrækning samt levering ved
					færdiggørelse af opgaven. Jeg har til huse i hjertet af Frederiksberg og tilbyder møbelpolstring for
					private, virksomheder og offentlige institutioner i København, Frederiksberg, Hovedstadsområdet,
					Nordsjælland og Sjælland.
					<br /> <br />
					Udover den traditionelle møbelpolstring har jeg også arbejdet som konsulent på møbel prototyper for
					større møbelvirksomheder.
					<br /> <br />
					Du er meget velkommen til at kontakte mig for en uforpligtende snak gennem kontaktformularen her:
				</p>
			</FullWidthContent> */}

      <FullWidthContent>
        <div style={{ maxWidth: "450px", margin: "0 auto" }}>
          <h2 style={{ textAlign: "left" }}>
            Kontakt mig helt uforpligtende her:
          </h2>
          <ContactForm />
        </div>
      </FullWidthContent>
    </Layout>
  )
}

export default IndexPage
